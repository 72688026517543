import api from '../axios.service'

export default {
  get() {
    return api.get('/apiBank')
  },
  list(data) {
    return api.get(`bank/list?search=${data.search}&size=${data.size}&page=${data.page}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },
  // eslint-disable-next-line camelcase
  show(PT_BankID) {
    // eslint-disable-next-line camelcase
    return api.get(`/apiBankShow?BankID=${PT_BankID}`)
  },
  bankStore(data){
    let getFormData = data

    let params = new FormData();
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }
    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.post(`bank/store`,params,config)
  },
  bankUpdate(data){
    let getFormData = data

    let params = new FormData();
    params.append('_method','PUT')
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }
    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.post(`/bank/update/${data.BankID}`,params,config)
  },


}
