/* eslint-disable no-undef */
/* eslint-disable import/no-cycle */
import api from '../axios.service'

export default {
  getPackage(data) {

    return api.get(`package/get_package?search=${data.search}&date=${data.date}&size=${data.size}&page=${data.page}&status=${data.status}`)
  },

  getMemberPackage(data) {

    return api.get(`package/get_member_packages`)
  },

  getPackageDetail(id) {

    return api.get(`package/get_package_detail/${id}`)
  },

  cancelProductAmt(data){
    let getFormData = data;
    let params = new FormData();
    params.append('_method', 'PUT');
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)  && getFormData[key]) {
        params.append(key, getFormData[key]);
      }
    }
    return api.post(`report_package/updateCreditProductAmtCanceled/${data.mem_package_id}`,params,{})
  },
  confirmProductAmt(id){
    let params = new FormData();
    params.append('_method', 'PUT');
    // for (var key in getFormData) {
    //   if (getFormData.hasOwnProperty(key)) {
    //     params.append(key, getFormData[key]);
    //   }
    // }
    return api.post(`report_package/updateCreditProductAmtConfirm/${id}`,params,{})
  },

  packageStore(data) {
    let getFormData = data

    let params = new FormData();
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)  && getFormData[key]) {
        params.append(key, getFormData[key]);
      }
    }
    // let config = {
    //   header: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }
    // return api.post(`payment_package/get_package?search=${data.search}&date=${data.date}&status=${data.OrderStatus}`)

    return api.post(`payment_package/store`, params, {})
  },
  packageUpdate(data) {
    let getFormData = data

    let params = new FormData();
    params.append('_method', 'PUT');
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key) && getFormData[key]) {
        params.append(key, getFormData[key]);
      }
    }
    // let config = {
    //   header: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }
    return api.post(`payment_package/update/${data.package_id}`,params)
  },
  packageUpdateStatus(id) {

    let params = new FormData();
    params.append('_method', 'PUT');
    // for (var key in getFormData) {
    //   if (getFormData.hasOwnProperty(key)) {
    //     params.append(key, getFormData[key]);
    //   }
    // }
    // let config = {
    //   header: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }
    return api.post(`package/package_updateStatus/${id}`,params)
  },
  packageDestroy(id) {

    return api.delete(`package/delete/${id}`)
  },

  /////////////////////////////////////////////

  getReportPackage(data) {

    return api.get(`report_package/get_report_package?search=${data.search}&date=${data.date}&status=${data.status}&page=${data.page}&size=${data.size}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },
  showPay(id){
    // '{{url('api/user/getMemberPackage').'/'}}' +  this.mem_package.mem_package_id + ''

    return api.get(`package/get_package_detail/${id}`)
  },
  approvePay(data) {

    console.log(data,666)
    let getFormData = data;
    let params = new FormData();
    params.append('_method', 'PUT');
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key) && getFormData[key]) {
        params.append(key, getFormData[key]);
      }
    }
    return api.post(`package/updateConfirmPay/${data.mem_package_pay_id}`,params,{})
  },
  cancelPay(data) {
    let getFormData = data;
    let params = new FormData();
    params.append('_method', 'PUT');
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key) && getFormData[key]) {
        params.append(key, getFormData[key]);
      }
    }
    return api.post(`package/updateConfirmPay/${data.mem_package_pay_id}?confirm_pay=C`,params,{})

  },
  deletePay(id) {

    let params = new FormData();

    return api.delete(`package/member_package_delete/${id}`,params,{})
  },
  addPackage(data,details) {
    let getFormData = data

    let params = new FormData();
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key) && getFormData[key]) {
        params.append(key, getFormData[key]);
      }
    }
    params.append('data',JSON.stringify(details))
    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.post(`package/add_package`, params, config)
  },

}
