/* eslint-disable no-undef */
/* eslint-disable import/no-cycle */
import axios from 'axios'
import api from '../axios.service'

const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
}
const baseURL = `${process.env.VUE_APP_BASEURL}`
export default {

  exchange(data){
    let getFormData = data
    let params = new FormData();
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }
    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.post(`user/exchange_point`,params,{})
  },

  getPackage(){

    return api.get(`user/package`)
  },
  getFollowPay(){

    return api.get(`user/get_member_package`)
  },
  showMemberPackage(id){
    // getMemberPackage
    return api.get(`user/show_member_package/${id}`)
  },
  getFollowPackageCredit(){

    return api.get(`user/get_member_package_credit`)
  },

  getBrand(){
    return api.get('user/get_brand');
  },
  get_model(brand_id){
    return api.get(`user/get_model/${brand_id}`);
  },

  getReportTaxNumber(data) {
    //   axios.get('{{url('api/user/order')}}?page=' + this.pagination.current_page + '&per_page=' + this.pagination.per_page + '&sortBy=' + this.sort + '&search=' + search + '&member_id=' + '{{auth()->user()->member_id}}' + '&type=all')
    return api.get(`user/order?type=all`)
  },

  getBookingOrder(data) {
    return api.get(`user/order?type=all`)
  },

  getReportMemberPackage(data) {
    return api.get(`user/get_member_package`)
  },

  getReportPackage(data) {
    return api.get(`user/report_package`)
  },

  getReportCredit(data) {
    return api.get(`user/credit_customer`)
  },

  getReportCreditMoney(data) {
    return api.get(`user/list?search=${data.search}&date=${data.date}&status=${data.OrderStatus}`)
  },

  getProductPoint(data) {
    return api.get(`user/product_points`)
  },
}
